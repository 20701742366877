<template>
    <div class="sld_account_list">
        <MemberTitle memberTitle="部门管理" style="padding-left: 20px"></MemberTitle>
        <div class="sld_account_list_one">
            <div class="mod1 flex-row justify-between">
                <span class="info1">部门管理</span>
            </div>
            <!-- <div class="mod2 flex_row_between_center">
              <div class="section2 flex_row_start_center">
                  <div class="info2 flex_row_center_center">
                      <span>会员名：</span>
                      <el-input v-model="name" maxlength="15" placeholder="请输入会员名" clearable @clear="getList"/>
                  </div>
                  <div class="info2 flex_row_center_center info2_11">
                      <span>创建时间：</span>
                        <el-date-picker
                          v-model="startTime.data"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始时间"
                          end-placeholder="结束时间"
                          :disabledDate="setDateRange"
                          value-format="YYYY-MM-DD HH:mm:ss"
                          >
                          </el-date-picker>
                  </div>
                  <div class="top_btn flex_row_center_center">
                      <div class="top_btn_search" @click="initList">搜索</div>
                      <div class="top_btn_reset" @click="reset">重置</div>
                  </div>
              </div>
          </div> -->
            <div class="section3" @click="operate('add')">添加部门</div>
            <div class="mod3">
                <div class="section4 flex_row_start_center">
                    <div class="info3 account">部门名称</div>
                    <!-- <div class="info3 phone">手机号</div> -->
                    <!-- <div class="info3 party">账号</div> -->
                    <!-- <div class="info3 email">审批流名称</div> -->
                    <div class="info3 time">添加时间</div>
                    <!-- <div class="info3 state">是否可用</div> -->
                    <div class="info3 operate">操作</div>
                </div>
                <div v-for="(item, index) in list.data" :key="index" class="section5 flex_row_start_center">
                    <div class="info4 account">{{ item.deptName }}</div>
                    <!-- <div class="info4 phone">{{ item.memberMobile || '--' }}</div> -->
                    <!-- <div class="info4 party">{{item.memberName}}</div> -->
                    <!-- <div class="info4 email">{{ item.approvalName }}</div> -->
                    <div class="info4 time">{{ item.createTime }}</div>
                    <!-- <div class="info4 state">
                        <el-switch v-model="item.stFlag" class="ml-2" style="--el-switch-on-color: #0e3177"
                            @change="subState(item.stFlag, item.memberId)" />
                    </div> -->
                    <div class="info4 operate flex_row_center_center">
                        <!-- <div class="block1" @click="operate('category',index)">设置分类</div> -->
                        <div class="block1" @click="operate('edit', index)">编辑</div>
                        <!-- <el-popconfirm
                          :title="item.state==1 ? '冻结后子账号将不能登录，是否确定冻结'
                              : '解冻后将恢复子账号功能，是否确定解冻'"
                          @confirm="operate('forbidden',index)">
                          <template #reference>
                              <div class="block1">{{item.state==1 ? '冻结' : '解冻'}}</div>
                          </template>
</el-popconfirm> -->
                        <!-- <el-popconfirm title="删除后不可恢复，是否确定删除" @confirm="operate('del', index)">
                            <template #reference>
                                <div class="block1">删除</div>
                            </template>
                        </el-popconfirm> -->
                    </div>
                </div>
                <SldCommonEmpty v-if="!list.data.length" totalWidth="1150" totalHeight="350" />
            </div>

            <!-- 分页 start -->
            <!-- <div class="flex_row_end_center sld_pagination">

                <el-pagination @current-change="handleCurrentChange" layout="prev, pager, next, jumper"
                    :page-size="pageData.pageSize" :currentPage="pageData.current" :total="pageData.total"
                    :hide-on-single-page="true">
                </el-pagination>
            </div> -->

            <!-- 分页 end -->

            <!-- 添加 start -->
            <el-dialog v-model="addVisible" title="部门管理" @close="close_operate('add')" width="560px">
                <div class="dialog1 flex_column_center_center">
                    <div class="dialog_title flex_row_between_center">
                        <span>添加部门</span>
                        <i class="iconfont icon-ziyuan51" @click="close_operate('add')"></i>
                    </div>
                    <div class="dialog_info flex_row_start_start">
                        <div class="dialog_info_tit"><span class="xing">部门名称</span></div>
                        <el-input
                            v-model="account.deptName"
                            @input="menberInfoss"
                            placeholder="请输入部门名称"
                            maxlength="20"
                            clearable
                            style="margin-bottom: 20px"
                        />
                    </div>
                    <!-- <div class="dialog_info flex_row_start_start">
                      <div class="dialog_info_tit"><span class="xing">登录密码：</span></div>
                      <el-input v-model="account.loginPwd" type="password" placeholder="请输入登录密码" maxlength="20" clearable/>
                  </div> -->
                    <!-- <div class="dialog_info flex_row_start_start">
                      <div class="dialog_info_tit"><span class="xing">确认密码：</span></div>
                      <el-input v-model="account.confirmPwd" type="password" placeholder="请输入确认密码" maxlength="20" clearable/>
                  </div> -->
                    <!-- <div class="dialog_info flex_row_start_start">
                        <div class="dialog_info_tit"><span class="xing">手机号：</span></div>
                        <el-input v-model="account.memberMobile" placeholder="请输入手机号" maxlength="11" clearable />
                    </div> -->
                    <!-- <div class="dialog_info flex_row_start_start">
                      <div class="dialog_info_tit">邮箱：</div>
                      <el-input v-model="account.email" placeholder="请输入邮箱" maxlength="25" clearable/>
                  </div> -->
                    <!-- <div class="dialog_info flex_row_start_start">
                        <div class="dialog_info_tit"><span class="xing">权限组：</span></div>
                        <el-select v-model="account.powerId" :no-data-text="'暂无数据'" placeholder="请选择权限组" clearable>
                            <el-option v-for="item in roleList.data" :key="item.powerId" :label="item.groupName"
                                :value="item.powerId">
                            </el-option>
                        </el-select>
                    </div> -->
                    <!-- <div class="dialog_info flex_row_start_start">
                        <div class="dialog_info_tit"><span class="xing">审批流：</span></div>
                        <el-select v-model="approvalCode" placeholder="请选择审批流" clearable @change="goapproval">
                            <el-option v-for="item in approvalList.data" :key="item.approvalCode"
                                :label="item.approvalName" :value="item.approvalCode">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="dialog_info flex_row_start_start" v-show="isShowAdd">
                        <div class="dialog_info_tit"><span class="xing">审批流名称：</span></div>
                        <el-input v-model="account.approvalName" placeholder="请输入审批流名称" maxlength="30" clearable />
                    </div> -->
                    <!-- <div class="dialog_info flex_row_start_start" style="height: 143px;padding-left: 55px;border-top: 1px solid #E5E5E5;padding-top: 20px;">
                      交易关闭订单
                      <el-steps direction="vertical" :active="3">
                          <el-step title="首审账号：user003" />
                          <el-step title="过审账号：......" />
                          <el-step title="终审账号：user001" />
                      </el-steps>
                      <div>

                      </div>
                  </div> -->
                    <div>
                        <!-- 交易关闭订单 -->
                        <!-- <el-steps direction="vertical" :active="3">
                            <el-step :title="item.title" v-for="(item, index) in addModel.data" :key="index">
                                <template v-slot:description>
                                    <div style="overflow:hidden;overflow-x: auto;width: 300px;">
                                        <el-dropdown trigger="click" v-if="index == 0">
                                            <div class="el-dropdown-link">
                                                <div class="box">
                                                    <el-image class="img1" style="width: 23px; height: 23px"
                                                        :src="require('@/assets/member/icon01.png')"
                                                        @click="handleClick(index)" />
                                                    <el-image class="img2" style="width: 9px; height: 9px"
                                                        :src="require('@/assets/member/icon02.png')"
                                                        @click.stop="handleClickReduce('first')" />
                                                </div>
                                                <div>
                                                    <span>{{ firstApproval.memberName }}</span>
                                                </div>
                                            </div>
                                            <template #dropdown>
                                                <el-dropdown-menu v-if="firstApprovalRoute.data.length > 0">
                                                    <el-dropdown-item :disabled="itemFirst.disabled"
                                                        v-for="(itemFirst, iFirst) in firstApprovalRoute.data"
                                                        :key="iFirst" @click="itemClick(index, itemFirst)">{{
                                                            itemFirst.memberName }}</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                        <div v-if="index == 1" style="display: flex;">
                                            <el-dropdown trigger="click"
                                                v-for="(center, iCen) in centreApprovalList.data" :key="iCen">
                                                <div class="el-dropdown-link">
                                                    <div class="box">
                                                        <el-image class="img1" style="width: 23px; height: 23px"
                                                            :src="require('@/assets/member/icon01.png')"
                                                            @click="handleClick(index, center)" />
                                                        <el-image class="img2" style="width: 9px; height: 9px"
                                                            :src="require('@/assets/member/icon02.png')"
                                                            @click.stop="handleClickReduce('center', iCen)" />
                                                    </div>
                                                    <div style="width: 50px;">
                                                        <span>{{ center.memberName }}</span>
                                                    </div>
                                                </div>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item :disabled="itemCenter.disabled"
                                                            v-for="(itemCenter, iCenter) in approvalCode || isShowAdd ? list.data : []"
                                                            :key="iCenter"
                                                            @click="itemClick(index, itemCenter, iCen)">{{
                                                                itemCenter.memberName }}</el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                            <div class="box" style="cursor: pointer;">
                                                <el-image class="img1" style="width: 23px; height: 23px"
                                                    :src="require('@/assets/member/icon03.png')"
                                                    @click="handleClickAdd" />
                                                <el-image class="img2" style="width: 9px; height: 9px"
                                                    :src="require('@/assets/member/icon02.png')"
                                                    @click.stop="handleClickReduce" />
                                            </div>
                                        </div>
                                        <el-dropdown trigger="click" v-if="index == 2">
                                            <div class="el-dropdown-link">
                                                <div class="box">
                                                    <el-image class="img1" style="width: 23px; height: 23px"
                                                        :src="require('@/assets/member/icon01.png')"
                                                        @click="handleClick(index)" />
                                                    <el-image class="img2" style="width: 9px; height: 9px"
                                                        :src="require('@/assets/member/icon02.png')"
                                                        @click.stop="handleClickReduce('last')" />
                                                </div>
                                                <div>
                                                    <span>{{ lastApproval.memberName }}</span>
                                                </div>
                                            </div>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item :disabled="itemLast.disabled"
                                                        v-for="(itemLast, iLast) in lastApprovalRoute.data" :key="iLast"
                                                        @click="itemClick(index, itemLast)">{{ itemLast.memberName
                                                        }}</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                    </div>
                                </template>
                            </el-step>
                        </el-steps> -->
                    </div>
                    <div class="dialog_btn flex_row_center_center">
                        <div class="dialog_submit" @click="submit_operate('add')">确定</div>
                        <div class="dialog_close" @click="close_operate('add')">取消</div>
                    </div>
                </div>
            </el-dialog>
            <!-- 添加 end -->

            <!-- 编辑 start -->
            <el-dialog v-model="editVisible" title="编辑部门" @close="close_operate('edit')" width="560px">
                <div class="dialog1 flex_column_center_center">
                    <div class="dialog_title flex_row_between_center">
                        <span>编辑部门</span>
                        <i class="iconfont icon-ziyuan51" @click="close_operate('edit')"></i>
                    </div>
                    <div class="dialog_info flex_row_start_start">
                        <div class="dialog_info_tit"><span class="xing">部门名称</span></div>
                        <el-input
                            v-model="account.deptName"
                            placeholder="请输入部门名称"
                            maxlength="20"
                            clearable
                            style="margin-bottom: 20px"
                        />
                        <!-- disabled -->
                    </div>
                    <!-- <div class="dialog_info flex_row_start_start">
                      <div class="dialog_info_tit"><span class="xing">登录密码：</span></div>
                      <el-input v-model="account.loginPwd" type="password" placeholder="请输入登录密码" maxlength="20" clearable/>
                  </div> -->
                    <!-- <div class="dialog_info flex_row_start_start">
                      <div class="dialog_info_tit"><span class="xing">确认密码：</span></div>
                      <el-input v-model="account.confirmPwd" type="password" placeholder="请输入确认密码" maxlength="20" clearable/>
                  </div> -->
                    <!-- <div class="dialog_info flex_row_start_start">
                      <div class="dialog_info_tit"><span class="xing">手机号：</span></div>
                      <el-input v-model="account.memberMobile" placeholder="请输入手机号" maxlength="11" clearable/>
                  </div> -->
                    <!-- <div class="dialog_info flex_row_start_start">
                      <div class="dialog_info_tit">邮箱：</div>
                      <el-input v-model="account.email" placeholder="请输入邮箱" maxlength="25" clearable/>
                  </div> -->
                    <!-- <div class="dialog_info flex_row_start_start">
                      <div class="dialog_info_tit"><span class="xing">权限组：</span></div>
                      <el-select v-model="account.powerId" placeholder="请选择权限组" clearable>
                          <el-option
                              v-for="item in roleList.data"
                              :key="item.powerId"
                              :label="item.groupName"
                              :value="item.powerId">
                          </el-option>
                      </el-select>
                  </div>
                  <div class="dialog_info flex_row_start_start">
                      <div class="dialog_info_tit"><span class="xing">审批流：</span></div>
                      <el-select v-model="approvalCode" placeholder="请选择审批流" clearable @change="goapproval">
                          <el-option
                              v-for="item in approvalList.data"
                              :key="item.approvalCode"
                              :label="item.approvalName"
                              :value="item.approvalCode">
                          </el-option>
                      </el-select>
                  </div>
                  <div class="dialog_info flex_row_start_start" v-show="isShowEdit">
                      <div class="dialog_info_tit"><span class="xing">审批流名称：</span></div>
                      <el-input v-model="account.approvalName" placeholder="请输入审批流名称" maxlength="30" clearable/>
                  </div> -->
                    <!-- <div class="dialog_info flex_row_start_start"
                        style="padding-left: 55px;border-top: 1px solid #E5E5E5;padding-top: 20px;"
                        v-if="approval.data">
                        <el-steps direction="vertical" :active="3">
                            <el-step :title="item.title" v-for="(item, index) in addModel.data" :key="index">
                                <template v-slot:description>
                                    <div style="overflow:hidden;overflow-x: auto;width: 300px;">
                                        <el-dropdown trigger="click" v-if="index == 0">
                                            <div class="el-dropdown-link">
                                                <div class="box">
                                                    <el-image class="img1" style="width: 23px; height: 23px"
                                                        :src="require('@/assets/member/icon01.png')"
                                                        @click="handleClick(index)" />
                                                    <el-image class="img2" style="width: 9px; height: 9px"
                                                        :src="require('@/assets/member/icon02.png')"
                                                        @click.stop="handleClickReduce('first')" />
                                                </div>
                                                <div>
                                                    <span>{{ firstApproval.memberName }}</span>
                                                </div>
                                            </div>
                                            <template #dropdown>
                                                <el-dropdown-menu v-if="firstApprovalRoute.data.length > 0">
                                                    <el-dropdown-item :disabled="itemFirst.disabled"
                                                        v-for="(itemFirst, iFirst) in firstApprovalRoute.data"
                                                        :key="iFirst" @click="itemClick(index, itemFirst)">{{
                                                            itemFirst.memberName }}</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                        <div v-if="index == 1" style="display: flex;">
                                            <el-dropdown trigger="click"
                                                v-for="(center, iCen) in centreApprovalList.data" :key="iCen">
                                                <div class="el-dropdown-link">
                                                    <div class="box">
                                                        <el-image class="img1" style="width: 23px; height: 23px"
                                                            :src="require('@/assets/member/icon01.png')"
                                                            @click="handleClick(index, center)" />
                                                        <el-image class="img2" style="width: 9px; height: 9px"
                                                            :src="require('@/assets/member/icon02.png')"
                                                            @click.stop="handleClickReduce('center', iCen)" />
                                                    </div>
                                                    <div style="width: 50px;">
                                                        <span>{{ center.memberName }}</span>
                                                    </div>
                                                </div>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item :disabled="itemCenter.disabled"
                                                            v-for="(itemCenter, iCenter) in approvalCode || isShowAdd ? list.data : []"
                                                            :key="iCenter"
                                                            @click="itemClick(index, itemCenter, iCen)">{{
                                                                itemCenter.memberName }}</el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                            <div class="box" style="cursor: pointer;">
                                                <el-image class="img1" style="width: 23px; height: 23px"
                                                    :src="require('@/assets/member/icon03.png')"
                                                    @click="handleClickAdd" />
                                                <el-image class="img2" style="width: 9px; height: 9px"
                                                    :src="require('@/assets/member/icon02.png')"
                                                    @click.stop="handleClickReduce" />
                                            </div>
                                        </div>
                                        <el-dropdown trigger="click" v-if="index == 2">
                                            <div class="el-dropdown-link">
                                                <div class="box">
                                                    <el-image class="img1" style="width: 23px; height: 23px"
                                                        :src="require('@/assets/member/icon01.png')"
                                                        @click="handleClick(index)" />
                                                    <el-image class="img2" style="width: 9px; height: 9px"
                                                        :src="require('@/assets/member/icon02.png')"
                                                        @click.stop="handleClickReduce('last')" />
                                                </div>
                                                <div>
                                                    <span>{{ lastApproval.memberName }}</span>
                                                </div>
                                            </div>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item :disabled="itemLast.disabled"
                                                        v-for="(itemLast, iLast) in lastApprovalRoute.data" :key="iLast"
                                                        @click="itemClick(index, itemLast)">{{ itemLast.memberName
                                                        }}</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                    </div>
                                </template>
                            </el-step>
                        </el-steps>
                    </div> -->
                    <div class="dialog_btn flex_row_center_center">
                        <div class="dialog_submit" @click="submit_operate('edit')">确定</div>
                        <div class="dialog_close" @click="close_operate('edit')">取消</div>
                    </div>
                </div>
            </el-dialog>
            <!-- 编辑 end -->

            <!-- 重置密码 start -->
            <!-- <el-dialog v-model="pwdVisible" title="重置密码" @close="close_operate('reset')" width="440px">
                <div class="dialog flex_column_center_center">
                    <div class="dialog_title flex_row_between_center">
                        <span>重置密码</span>
                        <i class="iconfont icon-ziyuan51" @click="close_operate('reset')"></i>
                    </div>
                    <div class="dialog_info flex_row_start_start">
                        <div class="dialog_info_tit"><span class="xing">登录密码：</span></div>
                        <el-input v-model="account.loginPwd" type="password" placeholder="请设置密码" maxlength="20"
                            clearable />
                    </div>
                    <div class="dialog_info flex_row_start_start">
                        <div class="dialog_info_tit"><span class="xing">确认密码：</span></div>
                        <el-input v-model="account.confirmPwd" type="password" placeholder="请确认密码" maxlength="20"
                            clearable />
                    </div>
                    <div class="dialog_btn flex_row_end_center">
                        <div class="dialog_close" @click="close_operate('reset')">取消</div>
                        <div class="dialog_submit" @click="submit_operate('reset')">确定变更</div>
                    </div>
                </div>
            </el-dialog> -->
            <!-- 重置密码 end -->
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, watchEffect, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import SldCommonEmpty from "@/components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
export default {
    name: "AccountList",
    components: { SldCommonEmpty, MemberTitle },
    setup() {
        const router = useRouter();
        const { proxy } = getCurrentInstance();
        const internalInstance = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const name = ref(""); //企业名称搜索
        const startTime = reactive({ data: [] }); //开始时间
        const list = reactive({ data: [{}] });
        const pageData = reactive({
            current: 1,
            pageSize: 10,
            total: 0
        });
        const addVisible = ref(false);
        const editVisible = ref(false);
        const pwdVisible = ref(false);
        const isShowAdd = ref(false);
        const isShowEdit = ref(false);
        const approvalCode = ref("");
        let firstApproval = reactive({
            //本地的首审数据
            memberName: "",
            memberId: "",
            deptName: ""
        });
        let lastApproval = reactive({
            //本地的终审数据
            memberName: "",
            memberId: "",
            deptName: ""
        });
        let centreApprovalList = reactive({
            data: [
                //本地的过审数据
                {
                    memberName: "",
                    memberId: "",
                    deptName: ""
                }
            ]
        });
        let firstApprovalRoute = reactive({ data: [] }); //接收的首审数据
        // let centreApprovalRoute = reactive({ data: [{memberName: '',memberId: ''}] });//接收的过审数据
        let lastApprovalRoute = reactive({ data: [] }); //接收的终伸数据
        const account = reactive({
            approvalParentId: "", //上级审批会员id
            memberId: "", //子账号id
            deptName: "",
            memberName: "", //子账号
            approvalName: "", //审批流名称
            loginPwd: "", //密码
            confirmPwd: "", //确认密码
            memberMobile: "", //手机号
            powerId: "" //权限组id
            // email: '', //邮箱
        });
        const roleList = reactive({ data: [] }); //权限组列表
        let approvalList = reactive({ data: [] }); //上级审批路线列表
        const approval = reactive({ data: [] }); //上级审批路线
        const addModel = reactive({
            data: [
                { title: "首审账号：", value: 1 },
                { title: "过审账号：", value: 2 },
                { title: "终审账号：", value: 3 }
            ]
        });
        const editModel = reactive({
            data: [
                { title: "首审账号：", value: 1 },
                { title: "过审账号：", value: 2 },
                { title: "终审账号：", value: 3 }
            ]
        });
        const setDateRange = (time) => {
            //   console.log(time); // time 为new Date()类型
            if (time.getTime() > new Date().getTime()) {
                return time.getTime() >= new Date().getTime(); //时间范围必须是时间戳
            } else {
                // return time.getTime() < Date.now() - 2 * 8.64e7; //8.64e7就是一天的时间戳 24*60*60*1000   两天之内 根据自己需求来定
            }
        };
        const initList = () => {
            pageData.current = 1;
            pageData.total = 0;
            getList();
        };

        const reset = () => {
            name.value = "";
            startTime.data = [];
            pageData.current = 1;
            pageData.total = 0;
            getList();
        };

        //获取列表
        const getList = () => {
            let param = {
                // memberName: name.value,
                current: pageData.current,
                pageSize: pageData.pageSize
            };
            console.log(param, "分页1111111111111");

            if (name.value.trim()) {
                param.memberName = name.value;
                //  param.deptName=name.value
                //  param.deptName=name.deptName
            }
            if (startTime.data) {
                if (startTime.data.length > 0) {
                    param.startTime = startTime.data[0];
                    param.endTime = startTime.data[1];
                }
            }
            proxy.$get("api/shopp/dept/query/list", param).then((res) => {
                //原接口v3/member/front/dept/list
                console.log(res, "0000000000000000000000000000000");
                if (res.code == 200) {
                    res.data.forEach((item) => {
                        item.stFlag = item.state == 1 ? true : false;
                        item.disabled = false;
                    });
                    list.data = res.data;
                    pageData.total = res.data.length;
                    //   pagination.total
                }
            });
        };

        //   //权限组列表
        //   const getRoleList =()=> {
        //       let param = {
        //           current: 1, //当前页面位置
        //           pageSize: 100, //分页大小
        //           // roleName: '', //权限组名称
        //       }
        //       proxy.$get('v3/member/front/power/list', param).then(res=>{
        //           roleList.data = res.data;
        //       })
        //   };

        // 上级审批路线列表
        //   const getapprovaList =()=> {
        //       proxy.$get('v3/member/front/subAccount/approvalList').then(res=>{
        //           if(res.state==200) {
        //               let obj = {
        //                   approvalCode: '1',
        //                   approvalName: '添加审批流'
        //               }
        //               res.data.push(obj);
        //               approvalList.data = res.data;
        //           }
        //       })
        //   };
        const handleClick = (i, e) => {
            return console.log(e, account.approvalName);
            let arr = [];
            flowList.data = [];
            if (e == 0 && approvalList.data[0].firstApprovalRoute) {
                console.log(approvalList.data[0].firstApprovalRoute);
                arr.push(approvalList.data[0].firstApprovalRoute);
                console.log(typeof flowList.data);
                // flowList.data = arr
            } else if (e == 1 && approvalList.data[0].centreApprovalRoute) {
                console.log(2222);
                flowList.data = approvalList.data[0].centreApprovalRoute;
            } else if (e == 2 && approvalList.data[0].lastApprovalRoute) {
                console.log(3333);
                flowList.data.push(approvalList.data[0].lastApprovalRoute);
            }
            console.log(flowList.data);
        };
        const itemClick = (i, e, j) => {
            if (i == 0) {
                for (let i = 0; i < list.data.length; i++) {
                    if (firstApproval.memberId && list.data[i].memberId == firstApproval.memberId) {
                        list.data[i].disabled = false;
                    }
                    if (list.data[i].memberId == e.memberId) {
                        list.data[i].disabled = true;
                    }
                }
                firstApproval.deptName = e.deptName;
                //   firstApproval.memberName = e.memberName;
                firstApproval.memberId = e.memberId;
            }
            if (i == 1) {
                // console.log(i,e)
                console.log(centreApprovalList.data);
                for (let i = 0; i < list.data.length; i++) {
                    if (
                        centreApprovalList.data[j].memberId &&
                        list.data[i].memberId == centreApprovalList.data[j].memberId
                    ) {
                        list.data[i].disabled = false;
                        continue;
                    }
                    if (list.data[i].memberId == e.memberId) {
                        list.data[i].disabled = true;
                    }
                }
                centreApprovalList.data[j].memberName = e.memberName;
                centreApprovalList.data[j].memberId = e.memberId;
            }
            if (i == 2) {
                for (let i = 0; i < list.data.length; i++) {
                    if (lastApproval.memberId && list.data[i].memberId == lastApproval.memberId) {
                        list.data[i].disabled = false;
                    }
                    if (list.data[i].memberId == e.memberId) {
                        list.data[i].disabled = true;
                    }
                }
                //   lastApproval.memberName = e.memberName;
                lastApproval.deptName = e.deptName;
                lastApproval.memberId = e.memberId;
                internalInstance.ctx.$forceUpdate();
                console.log(lastApproval);
            }
        };
        const handleClickAdd = () => {
            let index = centreApprovalList.data.length - 1;
            if (!centreApprovalList.data[index].memberId) {
                return ElMessage.info("请选择过审账号！");
            }
            let obj = {
                memberName: "",
                memberId: ""
            };
            centreApprovalList.data.push(obj);
        };
        const handleClickReduce = (e, i) => {
            if (e == "first") {
                if (firstApproval.memberId) {
                    for (let j = 0; j < list.data.length; j++) {
                        if (list.data[j].memberId == firstApproval.memberId) {
                            list.data[j].disabled = false;
                        }
                    }
                    firstApproval.memberId = "";
                    //   firstApproval.memberName = ''
                    firstApproval.deptName = "";
                }
            } else if (e == "last") {
                if (lastApproval.memberId) {
                    for (let j = 0; j < list.data.length; j++) {
                        if (list.data[j].memberId == lastApproval.memberId) {
                            list.data[j].disabled = false;
                        }
                    }
                    lastApproval.memberId = "";
                    //   lastApproval.memberName = '';
                    lastApproval.deptName = "";
                }
            } else if (e == "center") {
                for (let j = 0; j < list.data.length; j++) {
                    if (list.data[j].memberId == centreApprovalList.data[i].memberId) {
                        list.data[j].disabled = false;
                    }
                }
                centreApprovalList.data.splice(i, 1);
                if (centreApprovalList.data.length == 0) {
                    let obj = {
                        deptName: "",
                        //   memberName: '',
                        memberId: ""
                    };
                    centreApprovalList.data.push(obj);
                }
            } else {
                if (centreApprovalList.data.length == 1) {
                    return;
                }
                for (let i = 0; i < list.data.length; i++) {
                    if (
                        centreApprovalList.data[centreApprovalList.data.length - 1].memberId &&
                        centreApprovalList.data[centreApprovalList.data.length - 1].memberId == list.data[i].memberId
                    ) {
                        list.data[i].disabled = false;
                    }
                }
                centreApprovalList.data.pop();
            }
        };
        //会员是否可用
        const subState = (flag, id) => {
            let param = {};
            param.state = flag ? 1 : 0;
            param.memberId = id;
            proxy.$post("v3/member/front/subAccount/state", param).then((res) => {
                if (res.state == 200) {
                    ElMessage.success(res.msg);
                    getList();
                } else {
                    ElMessage.error(res.msg);
                }
            });
        };

        const operate = (type, index) => {
            if (type == "add") {
                approval.data = [];
                //   getapprovaList()
                addVisible.value = true;
            } else if (type == "category") {
                router.push(`/member/account/category?memberId=` + list.data[index].memberId);
            } else if (type == "edit") {
                approval.data = [];
                //   getapprovaList()
                setTimeout(() => {
                    account.memberId = list.data[index].memberId;
                    account.deptId = list.data[index].deptId;
                    //   account.memberName = list.data[index].er't'y't'r;
                    account.deptName = list.data[index].deptName;
                    account.memberMobile = list.data[index].memberMobile;
                    approvalCode.value = list.data[index].approvalCode;
                    account.approvalName = list.data[index].approvalName;
                    account.powerId = list.data[index].powerId;
                    account.approvalParentId = list.data[index].approvalParentId;
                    goapproval(approvalCode.value);
                    // approvalRoute(list.data[index].approvalParentId)
                    editVisible.value = true;
                }, 200);
            } else if (type == "forbidden") {
                let param = {};
                (param.isFreeze = list.data[index].state == 1 ? true : false), //冻结/解冻 true:冻结 false:解冻
                    (param.memberId = list.data[index].memberId), //子账号id
                    proxy.$post("v3/member/front/memberUser/isFreeze", param).then((res) => {
                        if (res.state == 200) {
                            ElMessage.success(res.msg);
                            getList();
                        } else {
                            ElMessage.error(res.msg);
                        }
                    });
            } else if (type == "reset") {
                pwdVisible.value = true;
                account.memberId = list.data[index].memberId;
            } else if (type == "del") {
                proxy
                    .$post("v3/member/front/subAccount/del", {
                        memberId: list.data[index].memberId
                    })
                    .then((res) => {
                        if (res.state == 200) {
                            ElMessage.success(res.msg);
                            getList();
                        } else {
                            ElMessage.error(res.msg);
                        }
                    });
            }
        };
        const approid = ref("");
        const goapproval = (e) => {
            console.log(list.data);
            for (let i = 0; i < list.data.length; i++) {
                if (list.data[i].disabled) {
                    list.data[i].disabled = false;
                }
                if (e == list.data[i].approvalCode || list.data[i].state != 1) {
                    list.data[i].disabled = true;
                }
            }
            console.log(list.data);
            if (e == 1) {
                console.log(1111);
                account.approvalName = "";
                isShowAdd.value = true;
                isShowEdit.value = true;
                if (firstApproval.memberId) {
                    firstApproval.memberId = "";
                    //   firstApproval.memberName= '';
                    firstApproval.deptName = "";
                }
                firstApprovalRoute.data = list.data;
                if (lastApproval.memberId) {
                    lastApproval.memberId = "";
                    //   lastApproval.memberName= '';
                    lastApproval.deptName = "";
                }
                lastApprovalRoute.data = list.data;
                let obj = {
                    deptName: "",
                    //   memberName: '',
                    memberId: ""
                };
                centreApprovalList.data = [];
                centreApprovalList.data.push(obj);
            } else {
                isShowAdd.value = false;
                isShowEdit.value = false;
                approvalCode.value = e;
                firstApprovalRoute.data = [];
                lastApprovalRoute.data = [];
                centreApprovalList.data = [];
                for (let i = 0; i < approvalList.data.length; i++) {
                    if (e == approvalList.data[i].approvalCode) {
                        console.log(3333);
                        if (approvalList.data[i].firstApprovalRoute) {
                            console.log(99999);
                            firstApprovalRoute.data.push(approvalList.data[i].firstApprovalRoute);
                            firstApproval.memberId = approvalList.data[i].firstApprovalRoute.memberId;
                            //   firstApproval.memberName= approvalList.data[i].firstApprovalRoute.memberName;
                            firstApproval.deptName = approvalList.data[i].firstApprovalRoute.deptName;
                            for (let j = 0; j < list.data.length; j++) {
                                if (firstApproval.memberId && list.data[j].memberId == firstApproval.memberId) {
                                    list.data[j].disabled = true;
                                }
                            }
                            firstApprovalRoute.data = list.data;
                        } else {
                            firstApproval.memberId = "";
                            //   firstApproval.memberName= '';
                            firstApproval.deptName = "";
                            for (let j = 0; j < list.data.length; j++) {
                                if (
                                    list.data[j].disabled &&
                                    e != list.data[j].approvalCode &&
                                    list.data[j].state == 1
                                ) {
                                    list.data[j].disabled = false;
                                }
                            }
                            firstApprovalRoute.data = list.data;
                        }
                        if (approvalList.data[i].lastApprovalRoute) {
                            console.log(4444);
                            lastApprovalRoute.data.push(approvalList.data[i].lastApprovalRoute);
                            lastApproval.memberId = approvalList.data[i].lastApprovalRoute.memberId;
                            //   lastApproval.memberName= approvalList.data[i].lastApprovalRoute.memberName;
                            lastApproval.deptName = approvalList.data[i].lastApprovalRoute.deptName;
                            for (let j = 0; j < list.data.length; j++) {
                                if (lastApproval.memberId && list.data[j].memberId == lastApproval.memberId) {
                                    list.data[j].disabled = true;
                                }
                            }
                            lastApprovalRoute.data = list.data;
                        } else {
                            console.log(5555);
                            lastApproval.memberId = "";
                            lastApproval.memberName = "";
                            lastApproval.deptName = "";
                            for (let j = 0; j < list.data.length; j++) {
                                if (
                                    list.data[j].disabled &&
                                    e != list.data[j].approvalCode &&
                                    list.data[j].memberId != firstApproval.memberId &&
                                    list.data[j].state == 1
                                ) {
                                    list.data[j].disabled = false;
                                }
                            }
                            lastApprovalRoute.data = list.data;
                        }
                        if (
                            approvalList.data[i].centreApprovalRoute &&
                            approvalList.data[i].centreApprovalRoute.length > 0 &&
                            approvalList.data[i].centreApprovalRoute[0] != null
                        ) {
                            for (let j = 0; j < approvalList.data[i].centreApprovalRoute.length; j++) {
                                centreApprovalList.data.push(approvalList.data[i].centreApprovalRoute[j]);
                            }
                            for (let k = 0; k < centreApprovalList.data.length; k++) {
                                if (!centreApprovalList.data[k].memberId) {
                                    centreApprovalList.data.splice(k, 1);
                                }
                                for (let l = 0; l < list.data.length; l++) {
                                    if (
                                        centreApprovalList.data[k].memberId &&
                                        list.data[l].memberId == centreApprovalList.data[k].memberId
                                    ) {
                                        list.data[l].disabled = true;
                                    }
                                }
                            }
                        } else {
                            let obj = {
                                deptName: "",
                                memberName: "",
                                memberId: ""
                            };
                            centreApprovalList.data.push(obj);
                            for (let i = 0; i < centreApprovalList.data.length; i++) {
                                if (
                                    centreApprovalList.data[i].memberId ||
                                    (!centreApprovalList.data[i].memberId && i != 0)
                                ) {
                                    centreApprovalList.data.splice(i, 1);
                                }
                            }
                        }
                    }
                }
                // approvalRoute(e)
            }
        };
        const approvalRoute = (id) => {
            let param = { memberId: id };
            proxy.$get("v3/member/front/subAccount/approvalRoute", param).then((res) => {
                if (res.state == 200) {
                    approval.data = res.data;
                } else {
                }
            });
        };
        const close_operate = (type) => {
            if (type == "add") {
                addVisible.value = false;
            } else if (type == "edit") {
                editVisible.value = false;
            } else if (type == "reset") {
                pwdVisible.value = false;
            }

            account.memberId = "";
            //   account.memberName = '';
            account.deptName = "";
            account.loginPwd = "";
            account.approvalName = "";
            account.memberMobile = "";
            account.powerId = "";
            account.approvalParentId = "";
            approvalCode.value = "";

            //   firstApproval = {memberName: '',memberId: ''};
            //   lastApproval = {memberName: '',memberId: ''};
            //   centreApprovalList.data = [{memberName: '',memberId: ''}];

            firstApproval = { deptName: "", memberId: "" };
            lastApproval = { deptName: "", memberId: "" };
            centreApprovalList.data = [{ deptName: "", memberId: "" }];

            firstApprovalRoute.data = [];
            lastApprovalRoute.data = [];
            for (let j = 0; j < list.data.length; j++) {
                if (list.data[j].disabled) {
                    list.data[j].disabled = false;
                }
            }
            router.go(0);
        };

        const submit_operate = (type) => {
            // else if(account.loginPwd != account.confirmPwd){
            //         ElMessage.info('登录密码与确认密码不一致');
            //         return;
            //     }
            // else if(!account.confirmPwd){
            //         ElMessage.info('请输入确认密码');
            //         return;
            //     }
            // else if(account.confirmPwd.length<6){
            //         ElMessage.info('确认密码至少6位');
            //         return;
            //     }
            let regMobile = /(1[3-9]\d{9}$)/;
            if (type == "add") {
                let reg_num = new RegExp("^[0-9]*$");
                let reg_nums = new RegExp("^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9_-]){1,20}$");
                if (!account.deptName) {
                    ElMessage.warning("请输入部门名称");
                    return;
                } else if (typeof account.deptName === "string" && account.deptName.trim() === "") {
                    ElMessage.warning("请输入正确部门名称");
                    return;
                }
                //   else if(!approvalCode.value && !account.approvalName){
                //       ElMessage.info('请输入审批流名称');
                //       return;
                //   }
                //   else if(reg_num.test(account.memberName)){
                //       ElMessage.info('登录账号不能全为数字');
                //       return;
                //   } else if(!reg_nums.test(account.memberName)){
                //       ElMessage.info('登录账号须由中、英文、数字、"-"及"_"组成');
                //       return;
                //   }else if(account.memberName.length<2||account.memberName.length>20){
                //       ElMessage.info('请输入2～20位的登录账号');
                //       return;
                //   }else if(!account.memberMobile){
                //       ElMessage.info('请输入手机号');
                //       return;
                //   }else if(!regMobile.test(account.memberMobile)){
                //       ElMessage.info('请输入正确的手机号');
                //       return;
                //   }else if(!account.powerId){
                //       ElMessage.info('请选择权限组');
                //       return;
                //   }
                // else if(account.approvalParentId.length==0){
                //     ElMessage.info('请选择上级审批账号');
                //     return;
                // }
                //   let centreApprovalId = [];
                //   if(centreApprovalList.data.length==1 && !centreApprovalList.data[0].memberId) {
                //       centreApprovalList.data = []
                //   }
                //   if(centreApprovalList.data.length>1 && !centreApprovalList.data[centreApprovalList.data.length-1].memberId) {
                //       centreApprovalList.data.pop()
                //   }
                //   for (let i = 0; i < centreApprovalList.data.length; i++) {
                //       if(centreApprovalList.data[i]) {
                //           centreApprovalId.push(centreApprovalList.data[i].memberId)
                //       }
                //   }
                console.log("555555555555");

                let param = {
                    deptName: account.deptName //	用户名（登录账号）

                    // loginPwd: account.loginPwd, //密码
                    //   mobile: account.memberMobile, //手机号
                    //   powerId: account.powerId, //权限组
                    //   approvalCode: approvalCode.value==1?'':approvalCode.value,//审批流code
                    //   approvalName: account.approvalName, //审批流名称
                    //   firstApproval: firstApproval.memberId,//首审批流
                    //   centreApprovalList: centreApprovalId,//过审批流
                    //   lastApproval: lastApproval.memberId,//终审批流
                    // approvalParentId: account.approvalParentId, //权限组
                    // registerChannel:1,
                };
                proxy.$post("api/shopp/dept/insert", param, "json").then((res) => {
                    //原接口v3/member/front/dept/insert
                    // v3/member/front/subAccount/add
                    if (res.code == 200) {
                        ElMessage.success(res.msg);
                        addVisible.value = false;
                        //   debugger
                        reset();
                        account.deptName = "";
                        // account.loginPwd = '';
                        account.approvalName = "";
                        account.memberMobile = "";
                        account.powerId = "";
                        account.approvalParentId = "";
                        firstApproval = { deptName: "", memberId: "" };
                        lastApproval = { deptName: "", memberId: "" };
                        centreApprovalList.data = [{ deptName: "", memberId: "" }];
                    } else {
                        ElMessage.error(res.msg);
                    }
                });
            } else if (type == "edit") {
                let reg_num = new RegExp("^[0-9]*$");
                if (!account.deptName) {
                    ElMessage.info("请输入部门名称");
                    return;
                }
                if (typeof account.deptName === "string" && account.deptName.trim() === "") {
                    ElMessage.warning("请输入正确部门名称");
                    return;
                }
                //   else if(reg_num.test(account.memberName)){
                //       ElMessage.info('登录账号不能全为数字');
                //       return;
                //   }else if(account.memberName.length<2||account.memberName.length>20){
                //       ElMessage.info('请输入2～20位的登录账号');
                //       return;
                //   }
                // else if(!account.loginPwd){
                //     ElMessage.info('请输入登录密码');
                //     return;
                // }else if(account.loginPwd.length<6){
                //     ElMessage.info('登录密码至少6位');
                //     return;
                // }
                //   else if(!account.memberMobile){
                //       ElMessage.info('请输入手机号');
                //       return;
                //   }else if(!regMobile.test(account.memberMobile)){
                //       ElMessage.info('请输入正确的手机号');
                //       return;
                //   }else if(!account.powerId){
                //       ElMessage.info('请选择权限组');
                //       return;
                //   }
                let centreApprovalId = [];
                if (centreApprovalList.data.length == 1 && !centreApprovalList.data[0].memberId) {
                    centreApprovalList.data = [];
                }
                if (
                    centreApprovalList.data.length > 1 &&
                    !centreApprovalList.data[centreApprovalList.data.length - 1].memberId
                ) {
                    centreApprovalList.data.pop();
                }
                for (let i = 0; i < centreApprovalList.data.length; i++) {
                    if (centreApprovalList.data[i]) {
                        centreApprovalId.push(centreApprovalList.data[i].memberId);
                    }
                }
                let param = {
                    deptName: account.deptName, //	用户名（登录账号）
                    deptId: account.deptId
                };
                //   debugger
                proxy.$post("api/shopp/dept/edit", param, "json").then((res) => {
                    //原接口v3/member/front/dept/edit
                    if (res.code == 200) {
                        ElMessage.success(res.msg);
                        editVisible.value = false;

                        //   getList();
                        //   account.memberName = '';
                        //   // account.loginPwd = '';
                        //   account.deptName = '';
                        //   account.memberMobile = '';
                        //   account.powerId = '';
                        //   account.approvalParentId = '';
                        // account.memberId = '';
                    } else {
                        ElMessage.error(res.msg);
                    }
                });
            } else if (type == "reset") {
                if (!account.loginPwd) {
                    ElMessage.info("请输入密码");
                    return;
                } else if (!account.confirmPwd) {
                    ElMessage.info("请输入确认密码");
                    return;
                }
                let param = {
                    memberId: account.memberId, //子账号id
                    loginPwd: account.loginPwd, //登录密码
                    confirmPwd: account.confirmPwd //确认密码
                };
                proxy.$post("v3/member/front/memberUser/resetPwd", param).then((res) => {
                    if (res.state == 200) {
                        ElMessage.success(res.msg);
                        pwdVisible.value = false;
                        getList();
                        account.memberId = "";
                        account.loginPwd = "";
                        account.confirmPwd = "";
                    } else {
                        ElMessage.error(res.msg);
                    }
                });
            }
        };

        const handleCurrentChange = (current) => {
            pageData.current = current;
            getList();
        };
        const menberInfoss = () => {
            //   account.memberName = account.memberName.replace(/\s/g,'')
            account.memberName = account.deptName.replace(/\s/g, "");
        };

        onMounted(() => {
            getList();
            //   getRoleList();
            //   getapprovaList();
        });

        return {
            L,
            internalInstance,
            name,
            list,
            pageData,
            addVisible,
            editVisible,
            pwdVisible,
            account,
            roleList,
            addModel,
            editModel,
            isShowAdd,
            isShowEdit,
            approvalCode,
            firstApproval,
            lastApproval,
            centreApprovalList,
            firstApprovalRoute,
            lastApprovalRoute,
            handleClick,
            initList,
            approvalRoute,
            reset,
            getList,
            //   getRoleList,
            operate,
            close_operate,
            submit_operate,
            handleCurrentChange,
            startTime,
            //   getapprovaList,
            approvalList,
            subState,
            approval,
            setDateRange,
            approid,
            goapproval,
            menberInfoss,
            itemClick,
            handleClickAdd,
            handleClickReduce
        };
    }
};
</script>

<style lang="scss" scoped>
@import "../../../style/member/accountsector.scss";
</style>
<style lang="scss">
.sld_account_list {
    // ::-webkit-scrollbar {
    //     width: 1150px;
    //     height: 11px;
    // }
    // ::-webkit-scrollbar-thumb {
    //     background: #DADADA;
    //     border-radius: 10px;
    // }
    .el-step__icon.is-text {
        background-color: $colorMain;
        border: 1px solid $colorMain;
        border-color: $colorMain;
    }

    .el-step__line {
        background-color: $colorMain;
    }

    .el-step__title.is-finish {
        color: #000;
    }

    .el-step__icon-inner {
        display: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        padding: 0;
    }

    .mod2 {
        .el-input__inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 220px;
            height: 30px;
        }

        .el-textarea__inner {
            width: 220px;
            height: 30px;
        }

        .el-input__suffix {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .info2_11 {
            .el-input__inner {
                width: 395px;
            }

            .el-range-separator {
                width: 10%;
                line-height: 23px;
            }

            .el-icon-time:before {
                bottom: -1px;
            }
        }
    }

    .dialog1 {
        margin-top: 60%;

        .dialog_title {
            width: 100%;
            height: 44px;
            background: $colorMain;
            padding: 12px 14px;
            color: #ffffff;

            span {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
            }

            i {
                font-size: 14px;
                cursor: pointer;
            }
        }

        .dialog_info {
            width: 491px;
            margin-top: 20px;

            .dialog_info_tit {
                width: 100px;
                line-height: 36px;
                flex-shrink: 0;
                color: #121212;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                text-align: right;
                white-space: nowrap;
                margin-right: 6px;

                .xing {
                    position: relative;

                    &:before {
                        content: "*";
                        position: absolute;
                        left: -7px;
                        color: #e2231a;
                    }
                }
            }
        }

        .el-input__inner {
            width: 380px;
            font-weight: 400;
            font-size: 14px;
            font-family: Microsoft YaHei;
        }

        .el-textarea__inner {
            width: 290px;
            min-height: 138px !important;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
        }

        .dialog_btn {
            width: 491px;
            border-top: 1px solid #f2f2f2;
            padding: 15px 20px;

            .dialog_close,
            .dialog_submit {
                width: 78px;
                height: 36px;
                line-height: 36px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                text-align: center;
                border-radius: 6px;
                margin-left: 12px;
                cursor: pointer;
            }

            .dialog_close {
                color: #666262;
                background: #eeeeee;
            }

            .dialog_submit {
                color: #ffffff;
                background: $colorMain;
            }
        }
    }

    .el-step__description {
        position: relative;
        top: -28px;
        left: 110px;
    }

    // .el-dropdown-link{
    .box {
        position: relative;

        .img1 {
            cursor: pointer;
        }

        .img2 {
            cursor: pointer;
            position: absolute;
        }
    }

    // }
}

.el-popconfirm {
    width: 220px;
}

.info2_11 .el-icon-time:before {
    position: relative;
    bottom: 5px;
}
</style>
